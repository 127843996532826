<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="请选择代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 120px" v-model="read" filterable clearable placeholder="是否核查"
					class="handle-select mr10">
					<el-option label="全部" value="0"></el-option>
					<el-option label="已核查" value="1"></el-option>
					<el-option label="未核查" value="2"></el-option>
				</el-select>
				<!-- 	<el-select size="small" style="width: 120px" v-model="query.status" filterable clearable placeholder="有无风险" class="handle-select mr10">
					<el-option label="全部" value="0"></el-option>
					<el-option label="风险" value="1"></el-option>
					<el-option label="无风险" value="2"></el-option>
				</el-select> -->
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.startTime"
					style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
			</div>

			<el-row>
				<el-col :span="24">
					<div id="ai-tab">
						<el-table :data="tableData" border class="table AI-tab-box" ref="multipleTable"
							header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
							:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
							:row-class-name="tableRowClassName" highlight-current-row @row-click="handleRowClick">
							<el-table-column prop="agentName" label="代理" align="center"></el-table-column>
							<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
							<el-table-column prop="userPhone" label="坐席" align="center" width="115"></el-table-column>
							<el-table-column prop="callDate" label="拨打时间" align="center" width="155"></el-table-column>
							<el-table-column prop="phoneNumber" label="客户手机号" align="center"
								width="115"></el-table-column>
							<!-- 	<el-table-column prop="status" label="是否风险" align="center" width="115">
							<template #default="scope">
								<el-tag type="danger" size="small" v-if="scope.row.status == true">风险</el-tag>
								<el-tag type="success" size="small" v-if="scope.row.status == false">无风险</el-tag>
							</template>
						</el-table-column> -->
							<el-table-column prop="hist" label="风险原因" align="center">
								<template #default="scope">
									<el-tag v-for="item in scope.row.hist" :key="item" type="danger" size="small"
										style="margin-right: 3px;margin-bottom: 6px;">
										{{ item.hit_content }}
									</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="read" label="是否核查" align="center" width="90">
								<template #default="scope">
									<el-tag type="danger" size="small" v-if="scope.row.read == false">未核查</el-tag>
									<el-tag type="info" size="small" v-if="scope.row.read == true">已核查</el-tag>
								</template>
							</el-table-column>

							<el-table-column prop="dispose" label="操作" align="center">
								<template #default="scope">
									<el-button size="small" type="text" icon="el-icon-download"
										@click.stop="downloadRecord2(scope.row)">下载</el-button>
									<el-button size="small" type="primary" :class="[theme]"
										@click="getRecordingDetailsShow(scope.row)">录音详情</el-button>
									<!-- <el-button size="small" type="primary" :class="[theme]" @click="getRecordingDetails(scope.row)">标记风险</el-button> -->
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-col>
			</el-row>

			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="录音详情" v-model="audioVisible" width="750px" @close="closeDialog()"
			:close-on-click-modal="false">
			<div class="tag-box">
				<p v-for="item in customerDetails.hist" :key="item">
					<span>命中规则：{{ item.hit_content }}-----{{ item.name }}</span>
					<br />
					<span v-if="item.hit_seats_keyword">命中词：{{ item.hit_seats_keyword }}</span>
				</p>
			</div>
			<audio-player ref="audio" class="audio-box" :audiofileurl="customerDetails.recordPath"
				:audiorow="customerDetails" :key="Audiotimer"></audio-player>
			<div style="padding: 20px;">{{ customerDetails.content }}</div>
			<div style="display: flex;flex-direction: row;justify-content: end;margin-top: 20px;">
				<el-button size="small" type="primary" :class="[theme]" @click="getAiRiskRecord">无风险</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="getshowel">标记风险</el-button>
			</div>
		</el-dialog>
		<el-dialog title="标记风险" v-model="riskVisible" width="550px" @close="closeDialog()"
			:close-on-click-modal="false">
			<el-form :model="riskForm" :rules="rules" ref="riskForm" label-width="100px">
				<el-form-item label="风险原因:" prop="label">
					<el-radio-group v-model="riskForm.label">
						<el-radio v-for="item in riskList" :key="item.value" :label="item.label" :value="item.value"
							style="margin: 12px 6px;"></el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="备注:" prop="remark"><el-input size="small" v-model="riskForm.remark"
						placeholder="请输入备注"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getAiRiskRecord2">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchDepartment,
		fetchUsersByDepartmentId,
		fetchDefaultUsers,
		AIInspectionList,
		riskRecordUpdate,
		AiRiskRecord
	} from '../api/index.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import AudioPlayer from '../components/audioPlayer2.vue';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import {
		download
	} from '../api/recordIndex';
	export default {
		name: 'AIinspection',
		components: {
			AudioPlayer
		},
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					agentId: '',
					companyId: '',
					userId: '',
					pageIndex: 1,
					pageSize: 30,
					startTime: '',
					read: '',
					subAgentId: ''
				},
				read: '未核查',
				time: '',
				Audiotimer: '',
				tableData: [],
				agents: [],
				companys: [],
				role: '',
				pageTotal: 0,
				audioVisible: false,
				riskVisible: false,
				riskForm: {
					dispose: false
				},
				isAdmin: false,
				isAgent: false,
				isSubAgent: false,
				customerDetails: {},
				riskList: [{
						label: '诈骗',
						value: '诈骗'
					},
					{
						label: '辱骂',
						value: '辱骂'
					},
					{
						label: '违规',
						value: '违规'
					},
					{
						label: '报备话术不一致',
						value: '报备话术不一致'
					},
					{
						label: '有投诉风险',
						value: '有投诉风险'
					},
					{
						label: '其他',
						value: '其他'
					}
				],
				activeRow: null,
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			this.init();
			this.getData();
		},

		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// tableRowClassName({
			// 	rowIndex
			// }) {
			// 	if ((rowIndex + 1) % 2 === 0) {
			// 		return 'oddRow';
			// 	}
			// 	return 'evenRow';
			// },
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			/* 切换部门 */
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			getData() {
				console.log(this.read, 'read');
				if (this.read == '全部' || this.read == 0) {
					this.query.read = 0;
				}
				if (this.read == '已核查' || this.read == 1) {
					this.query.read = 1;
				}
				if (this.read == '未核查' || this.read == 2) {
					this.query.read = 2;
				}
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					startTime: this.time,
					read: this.query.read,
					status: this.query.status
				};
				AIInspectionList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime() {
				if (this.query.startTime) {
					this.time = this.toDateTimeStr(this.query.startTime);
				} else {
					this.time = this.query.startTime;
				}
			},
			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			getriskVisible(row) {
				this.riskForm = row;
				this.riskVisible = true;
			},
			getriskRecordUpdate() {
				let data = {
					id: this.riskForm.id,
					dispose: this.riskForm.dispose,
					remark: this.riskForm.remark
				};
				riskRecordUpdate(data).then(res => {
					if (res.code == 200) {
						this.riskVisible = false;
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.success(res.message);
					}
				});
			},
			closeDialog() {
				this.riskVisible = false;
				this.audioVisible = false;
			},
			getRecordingDetailsShow(row) {
				this.audioVisible = true;
				this.customerDetails = row;
				this.Audiotimer = new Date().getTime();
			},
			getAiRiskRecord() {
				let data = {
					id: this.customerDetails.id,
					risk: false
				};
				AiRiskRecord(data).then(res => {
					if (res.code == 200) {
						this.audioVisible = false;
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.success(res.message);
					}
				});
			},
			getAiRiskRecord2() {
				let data = {
					id: this.customerDetails.id,
					risk: true,
					remark: this.riskForm.remark,
					label: this.riskForm.label
				};
				AiRiskRecord(data).then(res => {
					if (res.code == 200) {
						this.audioVisible = false;
						this.riskVisible = false;
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.success(res.message);
					}
				});
			},
			getshowel() {
				this.riskForm.remark = '';
				this.riskVisible = true;
			},
			//下载
			downloadRecord2(row) {
				let indexOf = row.recordPath.indexOf('=');
				let path = row.recordPath.substring(indexOf + 1);
				if (path === '') {
					this.$message.error('录音地址为空');
					return;
				}
				let date = new Date();
				let fileName = row.recordPath.substring(indexOf + 32);

				download(path).then(res => {
					let url = window.URL.createObjectURL(new Blob([res]), {
						type: 'application/mp3'
					});

					let link = document.createElement('a');

					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', fileName + '.mp3');
					document.body.appendChild(link);
					link.click();
				});
			},
			handleRowClick(row, event, column) {
				this.activeRow = row; // 更新被点击的行
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.red {
		color: #f56c6c;
	}

	.tag-box {
		padding: 6px 20px;
		display: flex;
		flex-direction: column;
	}

	.tag-box p {
		display: inline-block;
	}

	.tag-box p span {
		display: inline-block;
		background-color: #f56c6c;
		color: #fff;
		padding: 4px 6px;
		margin-bottom: 6px;
		border-radius: 4px;
	}
</style>